import { NavLink } from "react-router-dom";
import HomeIcon from "@mui/icons-material/Home";
import HandshakeIcon from "@mui/icons-material/Handshake";
import BrushIcon from "@mui/icons-material/Brush";
import LayersIcon from "@mui/icons-material/Layers";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import ConstructionIcon from "@mui/icons-material/Construction";
import CardIcon from "@mui/icons-material/CreditCard";
import BittingsIcon from "@mui/icons-material/Dvr";
import { Tooltip } from "@mui/material";
import { SideBarMenu } from "./CommonConstants";
import QuizIcon from "@mui/icons-material/Quiz";
import SelectorIcon from '@mui/icons-material/PlaylistAddCheck';
import CableIcon from '@mui/icons-material/Cable';
import { connect } from "react-redux";


const SideBar = (props) => {
  const getSideBarIcon = (menu) => {
    return menu === "Home" ? (
      <HomeIcon className="m-3 active-side-bar" />
    ) : menu === "AGREEMENTS" ? (
      <HandshakeIcon className="m-3 active-side-bar" />
    ) : menu === "CREATE A KEY SYSTEM" ? (
      <BrushIcon className="m-3 active-side-bar" />
    ) : menu === "ORDER AUTHORIZATION" ? (
      <LayersIcon className="m-3 active-side-bar" />
    ) : menu === "RETROFIT SEARCH" ? (
      <ConstructionIcon className="m-3 active-side-bar" />
    ) : menu === "TRAINING & RESOURCES" ? (
      <PeopleAltIcon className="m-3 active-side-bar" />
    ) : menu === "FAQs" ? (
      <QuizIcon className="m-3 active-side-bar" />
    ) : menu === "ELECTRONIC KEY RECORDS" ? (
      <CardIcon className="m-3 active-side-bar" />
    ) : menu === "MANAGE YOUR CARDED PROGRAMS" ? (
      <BittingsIcon className="m-3 active-side-bar" />
    ) : menu === "PROGRAM SELECTOR" ? (
      <SelectorIcon className="m-3 active-side-bar" />
      ) : menu === "AA CONNECT" ? (
        <CableIcon className="m-3 active-side-bar" />
        ) : (
      ""
    );
  };

  const getMenuLink = (menu) => {
    switch (menu) {
      case "AGREEMENTS":
        return "https://test-agreements.medeco.com";
      case "CREATE A KEY SYSTEM":
        return "https://test-mkdesignstudio.medeco.com";
      case "ORDER AUTHORIZATION":
        return "https://test-roa.medeco.com";
      case "Retrofit Search":
        return "/external/retrofit";
        case "PROGRAM SELECTOR":
          return "/external/program-selector";
      case "TRAINING & RESOURCES":
        return "https://www.medeco.com/en/resources";
      case "MANAGE YOUR CARDED PROGRAMS":
        return "https://test-keymanagement.medeco.com";
      case "ELECTRONIC KEY RECORDS":
        return "https://test-bittings.medeco.com";
      case "FAQs":
        return "/faqs";
        case "AA CONNECT":
          return "https://connect.assaabloy.com/";
      default:
        return "/";
    }
  };

  let sideBarContent =
    props.userRole.role === "sales" ? SideBarMenu.sales : SideBarMenu.user;
  return (
    <div
      className={"flex flex-col shadow-2xl shadow-slate-400 bg-black"}
      style={{ height: "calc(100% - 106px)" }}
    >
      <div>
        {props.isCollapse ? (
          <NavigateBeforeIcon
            className="m-3 float-right cursor-pointer collapse-btn"
            onClick={() => props.setIsCollapse(!props.isCollapse)}
          />
        ) : (
          <NavigateNextIcon
            className="m-3 float-right cursor-pointer collapse-btn"
            onClick={() => props.setIsCollapse(!props.isCollapse)}
          />
        )}
      </div>
      <hr className="border-[#968049]" />
      <div className="sidebarZ" style={{ overflow: "auto" }}>
        {sideBarContent.map((menu, index) => {
          return (
            <Tooltip
              className="menuItem"
              key={index}
              title={!props.isCollapse ? menu : ""}
              placement="right"
            >
              <NavLink
                to={`${getMenuLink(menu)
                  }`}
                end
                className="flex items-center hover:bg-slate-100"               
              >
                {getSideBarIcon(menu)}
                {props.isCollapse && (
                  <span className="active-side-bar text-size">{menu}</span>
                )}
              </NavLink>
            </Tooltip>
          );
        })}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  console.log(state, "state");
  return {
    userRole: state.userRole,
  };
};

export default connect(mapStateToProps, null)(SideBar);
